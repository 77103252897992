import React from "react";
import './navbar.css'
import {Boxface} from './boxface'


export const Navbar = () => {
    return(
      <div className="header-nav">
      <ul>
          {/* <Boxface/> */}
      </ul>
  </div>
    )
}
